import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Gallery from '@browniebroke/gatsby-image-gallery'

export const MyGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allImages {
        nodes {
          img {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 270
                height: 270
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          caption
        }
      }
    }
  `)
  const imagesArray: any = data.allImages.nodes
    .map((props: any) => {
      const { caption, img, title } = props
      if (!img) {
        console.warn(`Got null for ${JSON.stringify(props)}`)
        return null
      }
      return { ...img.childImageSharp, caption, title }
    })
    .filter(Boolean)

  return (
    <GalleryContainer>
      <Gallery images={imagesArray} />
    </GalleryContainer>
  )
}

const GalleryContainer = styled.section`
  background: rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1rem;
  width: 100%;
`
