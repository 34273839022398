import AbbeySaints from '../../assets/audio/Abbey-Saints.mp3'
import Ashes from '../../assets/audio/Ashes-to-Ashes.mp3'
import BeforeTheBlast from '../../assets/audio/Before-the-Blast.mp3'
import Breakout from '../../assets/audio/Breakout.mp3'
import BrightestNight from '../../assets/audio/Brightest-Night-On-Earth.mp3'
import Roses from '../../assets/audio/Christmas-Roses.mp3'
import EndCredits from '../../assets/audio/End-Credits.mp3'
import Escape from '../../assets/audio/Escape.mp3'
import MainTheme from '../../assets/audio/main-theme.mp3'
import RollTheme from '../../assets/audio/Roll-Theme.mp3'
import RollTheme2 from '../../assets/audio/Roll-Theme2.mp3'
import RoyalRoad from '../../assets/audio/Royal-Road.mp3'
import TheShadow from '../../assets/audio/The-Shadow-of-Edinburgh.mp3'
import WillowTree from '../../assets/audio/The-Willow-Tree.mp3'
import ToKill from '../../assets/audio/To-Kill.mp3'
import Visions from '../../assets/audio/Visions-of-the-Crash.mp3'

export interface AudioDataConfig {
  title: string
  audioSrc: any
  artist: string
  link: string
  vocals?: string
  vocalsLink?: string
}

export const AudioData = [
  {
    title: 'Abbey Saints',
    audioSrc: AbbeySaints,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
    vocals: 'Suzanne Waters',
    vocalsLink: 'https://twitter.com/SuzanneVocals',
  },
  {
    title: 'Ashes to Ashes',
    audioSrc: Ashes,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
    vocals: 'Happy Hour',
    vocalsLink: 'https://twitter.com/happyhourbandfl',
  },
  {
    title: 'Before the Blast',
    audioSrc: BeforeTheBlast,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
  },
  {
    title: 'Breakout',
    audioSrc: Breakout,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
  },
  {
    title: 'The Brightest Night on Earth',
    audioSrc: BrightestNight,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
  },
  {
    title: 'Christman Roses',
    audioSrc: Roses,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
  },
  {
    title: 'End Credits',
    audioSrc: EndCredits,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
  },
  {
    title: 'Escape',
    audioSrc: Escape,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
    vocals: 'Claire Acey',
    vocalsLink: 'https://twitter.com/TheBetaMachine',
  },
  {
    title: 'Main Theme',
    audioSrc: MainTheme,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
    vocals: 'Uyanga Bold',
    vocalsLink: 'https://twitter.com/uyangaboldmusic',
  },
  {
    title: 'Roll Theme',
    audioSrc: RollTheme,
    artist: 'Dcrypt',
    link: 'https://twitter.com/LuxdeCentral',
  },
  {
    title: 'Roll Theme 2',
    audioSrc: RollTheme2,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
  },
  {
    title: 'Royal Road',
    audioSrc: RoyalRoad,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
  },
  {
    title: 'The Shadow of Edinburgh',
    audioSrc: TheShadow,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
  },
  {
    title: 'The Willow Tree',
    audioSrc: WillowTree,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
  },
  {
    title: 'To Kill',
    audioSrc: ToKill,
    artist: 'Falconshield',
    link: ' https://twitter.com/falconshield',
  },
  {
    title: 'Visions of The Crash',
    audioSrc: Visions,
    artist: 'Oumi Kapila',
    link: 'https://twitter.com/oumimusic?lang=en',
  },
]
