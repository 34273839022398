import React from 'react'
import styled from 'styled-components'
import { GlassCard, H1, H2 } from '../../../components'
import { AudioPlayer } from '../../../features/gallery/AudioPlayer'
import Background from '../../../images/mint-bg.svg'
import { AudioData } from '../../../features/gallery/data'
import { MyGallery } from '../../../features/gallery/LightBox'
import { useTranslation } from 'react-i18next'
import Loadable from '@loadable/component'

const LoadableAudioPlayer = Loadable(
  () => import('../../../features/gallery/AudioPlayer'),
)

export const Gallery: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ParentSection>
      <NoBG>
        <H2>{t('songs')}</H2>
        <LoadableAudioPlayer tracks={AudioData} />
      </NoBG>

      <ChildSectionShortWidth>
        <H2>{t('gallery')}</H2>

        <MyGallery />
      </ChildSectionShortWidth>

      <ChildSectionShortWidth>
        <H2>{t('videos')}</H2>
        <VideoContainer>
          <div className="home-video__center">
            <div className="iframe-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/-_7qz7_o_2I"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className="home-video__center">
            <div className="iframe-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/uyomd1wSyrU"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </VideoContainer>
      </ChildSectionShortWidth>
    </ParentSection>
  )
}

export default Gallery

const ConfineHeight = styled.div`
  height: 25rem;
`

const ParentSection = styled.section`
  display: grid;
  grid-template-rows: auto;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: center;
  padding-top: 4rem;
  color: white;
  background: url(${Background}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100%;

  @media (min-width: 200px) {
    width: 100vw;
  }

  @media (min-width: 750px) {
  }

  @media (min-width: 1200px) {
    row-gap: 2rem;
  }
`

const NoBG = styled.section`
  position: relative;

  @media (min-width: 200px) {
    margin-top: 3rem;
    width: 95%;
  }
  @media (min-width: 750px) {
    padding-top: 1rem;
    width: 75%;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
`

const VideoContainer = styled.section`
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;

  @media (min-width: 200px) {
    padding: 0rem;
  }

  @media (min-width: 750px) {
    padding: 1rem;
  }
`

const ChildSectionShortWidth = styled.section`
  position: relative;
  margin-bottom: 5rem;

  @media (min-width: 200px) {
    margin-top: 3rem;
    width: 95%;
  }
  @media (min-width: 750px) {
    padding-top: 1rem;
    width: 75%;
  }
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
  }
`
